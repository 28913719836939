<template>
  <section v-if="showCookieBanner">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 fs-small">
          <div
            class="cookie-consent-div bg-light-3 pt-space-xs pb-space-sm px-space-sm d-flex justify-content-between"
          >
            <div class="">
              <p>
                ASU websites use cookies to enhance user experience, analyze
                site usage, and assist with outreach and enrollment. By
                continuing to use this site, you are giving us your consent to
                do this. Learn more about cookies on ASU websites in our
                <a
                  href="https://www.asu.edu/privacy/#cookies"
                  target="_blank"
                  class="rds-link"
                  >Privacy Statement</a
                >.
              </p>

              <button
                class="btn btn-primary fw-bold py-space-xxs px-space-xs"
                @click="handleAgreeClick()"
              >
                Ok, I agree
              </button>
            </div>
            <div>
              <button
                class="btn btn-light close-btn fw-bold p-space-xxxs d-flex justify-content-center align-items-center"
                aria-label="Close"
                @click="hideCookieBanner()"
              >
                <font-awesome-icon icon="times"></font-awesome-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";

const showCookieBanner = ref(false);

const getCookie = () => {
  let t = "; " + document.cookie;
  let i = t.split("; cookieconsent_status=");
  var value = i.length < 2 ? null : i.pop()!.split(";");

  showCookieBanner.value = value === null ? true : false;
};

const handleAgreeClick = () => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    "cookie banner",
    "ok, i agree",
    "cookie banner"
  );

  setCookie();
};

const setCookie = () => {
  let date = new Date();
  date.setDate(date.getDate() + 90);

  let cookie = [
    "cookieconsent_status=dismiss",
    "expires=" + date.toUTCString(),
    "secure",
    "path=/",
  ];
  document.cookie = cookie.join(";");

  showCookieBanner.value = false;
};

const hideCookieBanner = () => {
  setCookie();

  analyticsComposable.trackModalEvent(
    "onclick",
    "close",
    "click",
    "main content",
    "cookie banner",
    "close cross",
    "cookie banner"
  );

  showCookieBanner.value = false;
};

onMounted(() => {
  getCookie();
});
</script>

<style lang="scss" scoped>
.close-btn {
  width: 32px;
  height: 32px;
  border: solid 1px var(--rds-light-4, #d0d0d0);
  border-radius: 50%;
}
.close-btn:hover {
  cursor: pointer;
}
.cookie-consent-div {
  position: fixed;
  z-index: 1000;
  border: 1px solid var(--rds-light-4, #d0d0d0);
  bottom: 24px;
}
@media (max-width: 768px) {
  .cookie-consent-div {
    left: 15px;
    right: 15px;
  }
}
@media (min-width: 992px) {
  .cookie-consent-div {
    max-width: 676px;
  }
}
</style>
