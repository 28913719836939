<template>
  <div class="container-fluid bg-secondary fixed-bottom bottom-nav">
    <div class="row my-space-xxs">
      <div v-if="displayApplyNow" :class="getClass" class="btn-border">
        <button-apply-now
          :is-block="true"
          :type="applyNowType"
          :link-url="applyNowUrl"
          event-region="footer"
          event-section="sticky footer"
          :has-size-class="true"
          :display-icon="true"
          text-size="small"
        ></button-apply-now>
      </div>
      <div :class="getClass">
        <button
          v-if="displayContactButton"
          @click="showContactModal"
          class="btn btn-secondary d-block fw-bold btn-padding fs-small w-100"
        >
          <font-awesome-icon
            icon="comments"
            role="presentation"
          ></font-awesome-icon>
          Contact
        </button>
      </div>
    </div>
    <rds-modal
      ref="contactModal"
      class="nav-modal"
      :visible="displayModal"
      @hidden="displayModal = false"
      use-custom-close
    >
      <div class="p-space-md">
        <div class="d-flex justify-content-end align-items-center">
          <button
            type="button"
            aria-label="Close"
            class="modal-close ms-auto"
            @click="hideContactModal()"
          >
            ×
          </button>
        </div>

        <h3 class="mb-space-sm modal-title fw-bolder text-center">
          {{ modalTitle }}
        </h3>

        <button
          v-if="displayRfiCta"
          class="btn btn-secondary d-block w-100 fw-bold fs-medium mb-space-xs"
          @click="scrollToRFI"
          data-cy="rfi-button"
        >
          <font-awesome-icon
            icon="question-circle"
            role="presentation"
          ></font-awesome-icon>
          Request info
        </button>
        <div
          v-if="displayRfiCta"
          class="line-div d-flex align-items-center justify-content-center mb-space-xs px-space-lg"
        >
          or
        </div>
        <button
          v-if="displayChatCta"
          data-testing-value="support-chatbot-button"
          class="btn d-block w-100 btn-secondary fw-bold fs-medium mb-space-sm"
          @click="handleChatClick"
        >
          <font-awesome-icon
            icon="comments"
            role="presentation"
          ></font-awesome-icon>
          Chat
        </button>
      </div>
    </rds-modal>
  </div>
</template>

<script setup lang="ts">
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import RdsModal from "@rds-vue-ui/rds-modal/RdsModal.vue";
import ButtonApplyNow from "~/components/nav/ButtonApplyNow.vue";

interface Props {
  applyNowType?: "modal" | "link";
  applyNowUrl?: string;
  rfiAnchorId?: string;
  displayApplyNow?: boolean;
  displayRfiCta?: boolean;
  displayChatCta?: boolean;
  displayContactButton?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  applyNowType: "modal",
  applyNowUrl: "#",
  rfiAnchorId: "rfi-section",
  displayApplyNow: true,
  displayRfiCta: true,
  displayChatCta: true,
  displayContactButton: true,
});

const contactModal = ref<HTMLElement | null>();
const displayModal = ref(false);

const modalTitle = "How can we assist?";

const getClass = computed(() => {
  return props.displayApplyNow && props.displayContactButton
    ? "col-6"
    : "col-12";
});

const scrollToRFI = () => {
  // close modal
  displayModal.value = false;

  // scroll to RFI
  // add delay to allow modal to close
  setTimeout(() => {
    document.getElementById(props.rfiAnchorId)
      ? document.getElementById(props.rfiAnchorId)!.scrollIntoView({
          behavior: "smooth",
        })
      : null;
  }, 200);

  // analytics event
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    modalTitle.toLowerCase(),
    "request info",
    "navbar-bottom"
  );
};

const showContactModal = (event: Event) => {
  // show modal
  displayModal.value = true;

  // analytics event
  analyticsComposable.trackModalEvent(
    "onclick",
    "open",
    "click",
    "footer",
    "sticky footer",
    "contact",
    "navbar-bottom"
  );
};

const hideContactModal = () => {
  displayModal.value = false;

  // analytics event
  analyticsComposable.trackModalEvent(
    "onclick",
    "close",
    "click",
    "main content",
    modalTitle.toLowerCase(),
    "close cross",
    "navbar-bottom"
  );
};

const handleChatClick = (event: Event) => {
  // hide modal
  displayModal.value = false;

  analyticsComposable.trackEvent({
    event: "link",
    name: "onclick",
    action: "click",
    type: "internal link",
    region: "main content",
    section: modalTitle.toLowerCase(),
    text: "chat icon",
  });

  // open chatbot
  if (window && (window.embedded_svc as any)) {
    window.embedded_svc.onHelpButtonClick();
  }
};
</script>

<style lang="scss" scoped>
.bottom-nav {
  z-index: 900 !important;
}
.btn-border {
  border-right: 1px solid #d9a208;
}
.modal-title {
  font-size: 24px;
}
.line-div::before,
.line-div::after {
  background: black;
  height: 1px;
  flex: 1;
  content: "";
}

.line-div::before {
  margin-right: 16px;
}
.line-div::after {
  margin-left: 16px;
}

/* Small and medium devices (mobile, tablets, 768px and below) */
/* apply and request info button padding override for small and medium screens*/
@media (max-width: 768px) {
  .btn-padding {
    padding: 4px 8px;
  }
}
</style>
