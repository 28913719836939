<template>
  <div>
    <!-- project-brand section -->
    <div class="bg-dark-3 pt-space-sm pt-lg-space-md">
      <div class="container">
        <div class="row px-space-xs px-lg-0 mb-space-sm mb-lg-space-md">
          <div class="col-12 col-lg-6 mb-space-xs mb-lg-0">
            <img
              data-src="https://live-asuocms.ws.asu.edu/sites/default/files/asu-footer-logo.png"
              alt="ASU Online"
              class="img-fluid rds-lazy"
            />
          </div>
          <div class="col-12 col-md-6 my-lg-auto">
            <!-- social media icons -->
            <div
              class="d-flex flex-row flex-nowrap justify-content-lg-end align-items-center"
            >
              <NuxtLink
                v-for="(item, index) in $page?.baseFooter?.socialMediaItems"
                :key="index"
                :class="{
                  'me-space-sm': !isLastItem(
                    $page?.baseFooter.socialMediaItems as ParagraphHtmlLink[],
                    index
                  ),
                }"
                :href="item?.uri!"
                :title="item?.text"
                :aria-label="item?.text"
                :target="item?.target"
                @click.native="
                  handleSocialMediaLinkClick(item as ParagraphIconAtlas)
                "
              >
                <font-awesome-icon
                  v-if="item"
                  :icon="['fab', `${item.icon}`]"
                  :size="item.iconSize"
                  :aria-label="item.text"
                  class="text-white"
                />
              </NuxtLink>
            </div>
          </div>
        </div>
        <hr class="mx-space-xs mx-lg-0 my-0 footer-border-bottom" />
      </div>
    </div>

    <!-- primary-menu section -->
    <section class="bg-dark-3 text-white py-space-md py-lg-space-lg">
      <div class="container">
        <div class="row px-space-xs px-lg-0">
          <div class="col-12 col-lg-3">
            <div
              class="fs-large text-white fw-bold mb-space-sm"
              v-html="$page?.baseFooter.description"
            ></div>
            <!-- contact links -->
            <ul class="mb-space-md list-unstyled">
              <li
                class="d-flex flex-row flex-nowrap justify-content-left align-items-center pb-space-xxs"
              >
                <font-awesome-icon
                  role="presentation"
                  icon="sign-in-alt"
                  size="1x"
                  class="text-secondary fa-fw"
                ></font-awesome-icon>
                <button-apply-now
                  class="ms-space-xs"
                  text-size="small"
                  text-weight="normal"
                  type="modal"
                  link-url="#"
                  event-region="footer"
                  event-section="primary footer"
                  event-component="footer - global"
                  :has-size-class="true"
                  remove-button-styles
                ></button-apply-now>
              </li>
              <li
                v-for="(item, index) in $page?.baseFooter.contactItems"
                :key="index"
                class="d-flex flex-row flex-nowrap justify-content-left align-items-center"
                :class="{
                  'pb-space-xxs': !isLastItem(
                    $page?.baseFooter.contactItems!,
                    index
                  ),
                }"
              >
                <font-awesome-icon
                  v-if="item"
                  role="presentation"
                  :icon="item.icon"
                  :size="item.iconSize"
                  class="text-secondary fa-fw"
                ></font-awesome-icon>
                <NuxtLink
                  v-if="item"
                  class="ps-space-xs fs-small text-secondary"
                  :href="item.uri!"
                  :target="item.target"
                  @click.native="handleContactItemLinkClick(item)"
                >
                  {{ item.text }}
                </NuxtLink>
              </li>
            </ul>
            <!-- links mobile -->
            <rds-accordion start-collapsed>
              <collapse-item
                :title="item?.headingText!"
                title-size="large"
                button-size="custom"
                button-classes="px-0 py-space-sm"
                :collapse-id="`primary-footer-collapse-${index}`"
                title-background-variant="dark-3"
                title-variant="white"
                :display-highlight="false"
                border-variant="dark-3"
                icon-variant="white"
                content-background-variant="dark-3"
                @collapse-shown="handleCollapseShown(item!)"
                @collapse-hidden="handleCollapseHidden(item!)"
                v-for="(item, index) in $page?.baseFooter.menuItems"
                class="d-block d-lg-none border-top border-white"
                :class="{
                  'border-bottom':
                    index + 1 == $page?.baseFooter.menuItems?.length,
                }"
              >
                <div
                  class="collapse-content mb-space-xs"
                  v-if="item && item.linkItems"
                >
                  <p
                    v-for="(linkItem, linkIndex) in item.linkItems"
                    :key="linkIndex"
                  >
                    <NuxtLink
                      v-if="linkItem"
                      :title="linkItem.text"
                      :aria-label="linkItem.text"
                      class="mb-space-xs fs-medium text-white"
                      :href="linkItem.uri!"
                      @click.native="
                        handleLinkItemClick(
                          'primary footer',
                          linkItem,
                          'footer - global'
                        )
                      "
                      :target="formatLinkTarget(linkItem.target!)"
                    >
                      {{ linkItem.text }}
                    </NuxtLink>
                  </p>
                </div>
              </collapse-item>
            </rds-accordion>
          </div>

          <!-- links Desktop -->
          <div class="d-none d-lg-block col-lg-9">
            <!-- row 1 -->
            <div
              class="row pb-space-lg"
              v-if="
                $page?.baseFooter.menuItems &&
                $page?.baseFooter.menuItems.slice(0, 4)
              "
            >
              <div
                v-for="menuItem in $page?.baseFooter.menuItems.slice(0, 4)"
                class="col-3"
              >
                <p class="fs-medium text-white fw-bold mb-space-sm">
                  {{ menuItem!.headingText }}
                </p>
                <p
                  v-for="(linkItem, linkIndex) in menuItem!.linkItems"
                  :class="
                    !isLastItem(menuItem!.linkItems!, linkIndex)
                      ? 'mb-space-xxs'
                      : 'mb-0'
                  "
                >
                  <NuxtLink
                    v-if="linkItem"
                    :title="linkItem.text"
                    :aria-label="linkItem.text"
                    class="fs-small text-white"
                    :href="linkItem.uri!"
                    :target="formatLinkTarget(linkItem.target!)"
                    @click.native="
                      handleLinkItemClick(
                        'primary footer',
                        linkItem,
                        'footer - global'
                      )
                    "
                  >
                    {{ linkItem.text }}
                  </NuxtLink>
                </p>
              </div>
            </div>
            <!-- row 2 -->
            <div class="row">
              <div
                v-for="(
                  menuItem, menuIndex
                ) in $page?.baseFooter.menuItems?.slice(4)"
                class="col-3"
              >
                <p class="fs-medium text-white fw-bold mb-space-xs">
                  {{ menuItem?.headingText }}
                </p>
                <template v-if="menuItem?.linkItems">
                  <p
                    v-for="(linkItem, linkIndex) in menuItem?.linkItems"
                    :class="
                      !isLastItem(menuItem.linkItems, linkIndex)
                        ? 'mb-space-xxs'
                        : 'mb-0'
                    "
                  >
                    <NuxtLink
                      v-if="
                        linkItem &&
                        linkItem.text &&
                        linkItem.uri &&
                        linkItem.target
                      "
                      :title="linkItem.text"
                      :aria-label="linkItem.text"
                      class="fs-small text-white"
                      :href="linkItem.uri"
                      :target="formatLinkTarget(linkItem.target)"
                      @click.native="
                        handleLinkItemClick(
                          'primary footer',
                          linkItem!,
                          'footer - global'
                        )
                      "
                    >
                      {{ linkItem.text }}
                    </NuxtLink>
                  </p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { CollapseItem } from "@rds-vue-ui/collapse-item";
import { RdsAccordion } from "@rds-vue-ui/rds-accordion";
import { useUtilityService } from "~/composables/useUtilityService";
import {
  BaseFooter,
  ParagraphHtmlLink,
  ParagraphIconAtlas,
  ParagraphMenuItem,
} from "~/server/resolvers/resolvers-types";
import { LinkItem } from "~/types/types";
import ButtonApplyNow from "./ButtonApplyNow.vue";

const { formatLinkTarget } = useUtilityService();

interface MenuItem {
  headingText: string;
  isActive: boolean;
  linkItems: LinkItem[];
}

interface SocialMediaItem extends LinkItem {
  icon: string;
  iconSize: string;
  gaText: string;
}

interface ContactItem extends SocialMediaItem {}

interface FooterItems {
  baseFooter: BaseFooter;
}

const $page = await usePageQuery<FooterItems>(
  `#graphql
  query{
    baseFooter(id: "684f0e19-70e6-4957-89ac-3a484f5d37da"){
      description
      contactItems {
        icon
        iconSize
        gaText
        uri 
        text
        target
      }
      socialMediaItems {
        icon
        iconSize
        gaText
        uri 
        text
        target
      }
      menuItems {
        headingText
        isActive
        linkItems {
          text
          uri
          target
        }
      }
    }
  }
  `,
  {},
  {
    key: "footer-nav-items",
  }
);

const isLastItem = (collection: Array<any>, currentIndex: number) => {
  return currentIndex === collection.length - 1;
};

const handleSocialMediaLinkClick = (item: ParagraphIconAtlas) => {
  if (item?.gaText) {
    analyticsComposable.trackLinkEvent(
      "external link",
      "footer",
      "primary footer",
      item.gaText.toLowerCase(),
      "footer - global"
    );
  } else {
    console.error("missing ga text");
  }
};

const handleContactItemLinkClick = (item: ParagraphIconAtlas) => {
  if (item.text) {
    analyticsComposable.trackLinkEvent(
      "external link",
      "footer",
      "primary footer",
      item.text.toLowerCase(),
      "footer - global"
    );
  } else {
    console.error("missing link text");
  }
};

const handleLinkItemClick = (
  section: string,
  item: ParagraphHtmlLink,
  component: string
) => {
  if (item.text) {
    analyticsComposable.trackLinkEvent(
      "internal link",
      "footer",
      section.toLowerCase(),
      item.text.toLowerCase(),
      component.toLowerCase()
    );
  } else {
    console.error("missing link text");
  }
};

const handleCollapseShown = (item: ParagraphMenuItem) => {
  if (item.headingText) {
    analyticsComposable.trackEvent({
      event: "collapse",
      name: "onclick",
      action: "open",
      type: "click",
      region: "footer",
      section: "primary footer",
      text: item.headingText.toLowerCase(),
      component: "footer - global",
    });
  } else {
    console.error("missing collapse title text");
  }
};

const handleCollapseHidden = (item: ParagraphMenuItem) => {
  if (item.headingText) {
    analyticsComposable.trackEvent({
      event: "collapse",
      name: "onclick",
      action: "close",
      type: "click",
      region: "footer",
      section: "primary footer",
      text: item.headingText.toLowerCase(),
      component: "footer - global",
    });
  } else {
    console.error("missing collapse title text");
  }
};
</script>

<style lang="scss" scoped>
.mobile-menu-collapse {
  margin: -1px;
}
a:focus {
  outline: 2px solid var(--rds-light-2, #f1f1f1);
}
</style>
